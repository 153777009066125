import { STORE_OBJECT_TREE_DRIVERS, TOGGLE_OBJECT_TREE } from '../constants';

const getInitialState = () => ({
    drivers: [],
    isToggled: true,
});

const handleObjectTreeToggle = (state) => {
    const { isToggled } = state;
    return {
        ...state,
        isToggled: !isToggled,
    };
};

const CommonReducer = (state = getInitialState(), action = {}) => {
    switch (action.type) {
        case STORE_OBJECT_TREE_DRIVERS: {
            const driversInObjectTreeFormat = action.payload.drivers.map((driver) => {
                const { id, first_name, last_name } = driver;
                return {
                    id: id,
                    name: {
                        firstName: first_name,
                        lastName: last_name,
                    },
                    type: 'driver',
                };
            });
            return {
                ...state,
                drivers: driversInObjectTreeFormat,
            };
        }
        case TOGGLE_OBJECT_TREE: {
            return handleObjectTreeToggle(state);
        }
        default:
            return state;
    }
};

export default CommonReducer;
