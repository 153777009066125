import { Log, UserManager } from 'oidc-client-ts';

export const handleLoginRedirect = (injectedWindow = window) => {
    const runsInIframe = injectedWindow.parent && injectedWindow.parent !== injectedWindow;

    if (runsInIframe) {
        // Silent redirect within an <iframe>
        Log.logger = console;
        Log.level = Log.INFO;

        // This will propagate the received information provided via
        // query parameters to the parent frame
        new UserManager({}).signinSilentCallback();
    } else {
        window.location.replace(`${window.location.origin}#/?${window.location.hash.replace('#', '')}`);
    }
};
