/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router';

const NonMatchingLink = ({ to, children, ...rest }) => {
    const location = useLocation();
    const isActive = location.pathname === to;

    return isActive ? (
        <span>{children}</span>
    ) : (
        <Link to={to} {...rest}>
            {children}
        </Link>
    );
};

NonMatchingLink.propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default NonMatchingLink;
