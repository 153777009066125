import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { hideSessionExpiredDialog } from './actions/pdcActions';

export const UnconnectedActionsContainer = ({ children }) => children;

UnconnectedActionsContainer.propTypes = {
    hideSessionDialog: PropTypes.func.isRequired,
};

export const mapStateToProps = () => ({});

export const mapDispatchToProps = (dispatch) => ({
    hideSessionDialog: () => {
        dispatch(hideSessionExpiredDialog());
    },
});

const ActionsContainer = connect(mapStateToProps, mapDispatchToProps)(UnconnectedActionsContainer);

export default ActionsContainer;
