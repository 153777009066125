import get from 'lodash/get';

import { getAuthenticatedFetchJSON } from './fetch';

import getServiceLocation from '../lib/getServiceLocation';

const fetchVehicles = (injectedFetch = fetch) => {
    const configuredFetch = getAuthenticatedFetchJSON(injectedFetch);

    return configuredFetch(`${getServiceLocation('assetsApi')}/assets?status=active`)
        .then((res) => get(res, 'items', []))
        .catch(() => []);
};

export default fetchVehicles;
