/* eslint-disable max-lines-per-function */
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { getSelectedInspection } from '../../selectors';
import { getIssuesTranslationKey } from '../PDCPageComponent';

const BaseComponent = ({ pdc = {} }) => {
    const { damagesCount, criticalDamagesCount } = pdc;

    return (
        <div className={'row'} data-testid={'damages-header-container'}>
            <div className={'display-flex'}>
                <div className={'col-lg-6 margin-bottom-10'}>
                    <div className={'text-size-small text-color-gray'}>
                        <FormattedMessage id={'pdc.table.header.criticalIssueCount'} />
                    </div>
                    <div className={`text-color-${criticalDamagesCount && 'danger text-bold'}`}>
                        {criticalDamagesCount ? (
                            <span>
                                <i className={'rioglyph rioglyph-warning-sign'} />
                                &nbsp;
                            </span>
                        ) : (
                            ''
                        )}
                        <FormattedMessage
                            id={getIssuesTranslationKey(criticalDamagesCount)}
                            values={{ issueCount: criticalDamagesCount }}
                        />
                    </div>
                </div>
                <div className={'col-lg-6 margin-bottom-10'}>
                    <div className={'text-size-small text-color-gray'}>
                        <FormattedMessage id={'pdc.table.header.issueCount'} />
                    </div>
                    <div className={`text-color-${damagesCount && 'warning text-bold'}`}>
                        <FormattedMessage
                            id={getIssuesTranslationKey(damagesCount)}
                            values={{ issueCount: damagesCount }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

BaseComponent.propTypes = {
    pdc: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => ({
    pdc: getSelectedInspection(state),
});

export { BaseComponent };
export default injectIntl(connect(mapStateToProps)(BaseComponent));
