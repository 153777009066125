/* eslint-env node */

import noop from 'lodash/noop';

import Application from './Application';
import { handleLoginRedirect } from './features/login/redirect';
import getServiceLocation from './lib/getServiceLocation';

// eslint-disable-next-line no-process-env
const noTestRun = import.meta.env.VITE_NO_TEST_RUN;

if (window.location.href.startsWith(getServiceLocation('redirect'))) {
    handleLoginRedirect();
}

const start = (injectedApplication) => injectedApplication();

start(noTestRun ? Application : noop);

export default start;
