import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import { DEFAULT_LOCALE } from '../constants';

export const dateFormat = 'L';
export const timeFormat = 'LT';

let formatMessage = null;
let locale = DEFAULT_LOCALE;

export function updateIntl(theLocale, theFormatMessage) {
    formatMessage = theFormatMessage;
    locale = theLocale;
}

export function getLocale() {
    return locale;
}

export function getFormatMessage() {
    return formatMessage;
}

export function isYesterday(date) {
    // eslint-disable-next-line no-magic-numbers
    return moment().subtract(1, 'days').format('YYYY-MM-DD') === date.format('YYYY-MM-DD');
}

export function isToday(date) {
    return moment().format('YYYY-MM-DD') === date.format('YYYY-MM-DD');
}

// eslint-disable-next-line max-statements
export function formatDateTime(inputDate) {
    if (isEmpty(inputDate) || !moment(inputDate.valueOf()).isValid()) {
        return null;
    }

    const date = moment(inputDate.valueOf());

    date.locale(getLocale());
    const prefix = date.format(dateFormat);
    return `${prefix}, ${date.format(timeFormat)}`;
}
