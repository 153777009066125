import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import LoginReducer from './features/login/reducer';
import ChangelogReducer from './reducers/ChangelogReducer';
import CommonReducer from './reducers/CommonReducer';
import FilterReducer from './reducers/FilterReducer';
import objectTreeReducer from './reducers/objectTreeReducer';
import PDCReducer from './reducers/PDCReducer';
import ProductActivationReducer from './reducers/ProductActivationReducer';
import UIStateReducer from './reducers/UIStateReducer';
import { runSagas } from './sagas';

export const logStoreChange = (injectedConsole, action, state, noTestRun) => {
    if (noTestRun) {
        injectedConsole.group(action.type);
        injectedConsole.info('dispatching', action);
        injectedConsole.log('state', state);
        injectedConsole.groupEnd(action.type);
    }
};

const logger = createLogger();
const sagaMiddleware = createSagaMiddleware();
const middleWare = [sagaMiddleware, logger];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const createStoreWithMiddleware = composeEnhancers(applyMiddleware(...middleWare))(createStore);

const store = createStoreWithMiddleware(
    combineReducers({
        common: CommonReducer,
        form: formReducer,
        pdc: PDCReducer,
        productActivation: ProductActivationReducer,
        uiState: UIStateReducer,
        login: LoginReducer,
        changelog: ChangelogReducer,
        filters: FilterReducer,
        objectTree: objectTreeReducer,
    }),
);

runSagas(sagaMiddleware);

store.dispatch({
    payload: {
        navigation: 'TREETYPE_DRIVER',
    },
    type: 'forest/FILTER',
});

export default store;
