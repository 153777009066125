/* eslint-disable max-lines-per-function */
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedDate, FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { getSelectedInspection } from '../../selectors';

const BaseComponent = ({ pdc }) => {
    const { submittedAt, vin, vehicle, driver, mileage, trailer, location } = pdc || {};

    return (
        <div data-testid={'basic-info-container'}>
            <div className={'row'}>
                <div className={'padding-bottom-5'}>
                    <FormattedMessage id={'report.pdc.reportedOn'} /> &nbsp;
                    <FormattedDate value={submittedAt} year={'numeric'} month={'long'} day={'2-digit'} />
                </div>
            </div>
            <div className={'row'}>
                <div className={'padding-top-5 padding-bottom-10 text-size-18'}>
                    <i className={'rioglyph rioglyph-angle-double-right'} /> {vehicle || vin}
                </div>
                <hr />
            </div>
            <div className={'row display-flex flex-wrap'}>
                {driver && (
                    <div className={'col-lg-6 margin-bottom-10'}>
                        <div className={'text-size-small text-color-gray'}>
                            <FormattedMessage id={'report.pdc.driver'} />
                        </div>
                        <div>{driver}</div>
                    </div>
                )}

                {mileage && (
                    <div className={'col-lg-6 margin-bottom-10'}>
                        <div className={'text-size-small text-color-gray'}>
                            <FormattedMessage id={'report.pdc.mileage'} />
                        </div>
                        <div>
                            {/*  eslint-disable-next-line no-magic-numbers */}
                            {mileage.value.toFixed(3)} km
                        </div>
                    </div>
                )}

                {trailer && (
                    <div className={'col-lg-6 margin-bottom-10'}>
                        <div className={'text-size-small text-color-gray'}>
                            <FormattedMessage id={'report.pdc.trailerName'} />
                        </div>
                        <div>{trailer}</div>
                    </div>
                )}

                {location && (
                    <div className={'col-lg-6 margin-bottom-10'}>
                        <div className={'text-size-small text-color-gray'}>
                            <FormattedMessage id={'report.pdc.location'} />
                        </div>
                        <div>{location.address}</div>
                    </div>
                )}
            </div>
        </div>
    );
};

BaseComponent.propTypes = {
    pdc: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => ({
    pdc: getSelectedInspection(state),
});

export { BaseComponent };
export default injectIntl(connect(mapStateToProps)(BaseComponent));
