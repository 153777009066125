import { SessionExpiredDialog } from '@rio-cloud/rio-session-expired-info';
import ApplicationHeader from '@rio-cloud/rio-uikit/lib/es/ApplicationHeader';
import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';
import NotificationsContainer from '@rio-cloud/rio-uikit/lib/es/NotificationsContainer';
import IframeResizer from 'iframe-resizer-react';
import PropTypes from 'prop-types';
import React from 'react';
import { IntlProvider, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { DEFAULT_LOCALE } from '../constants';
import getServiceLocation from '../lib/getServiceLocation';

import { getDrivers } from '../selectors';

import AppObjectTree from './AppObjectTree';
import ChangelogComponent from './Changelog/ChangelogComponent';
import optional from './common/Optional';
import ServiceInfoComponent from './ServiceInfoComponent';
import SidebarContainer from './Sidebar/SidebarContainer';

import { RioNotifications } from '@rio-cloud/rio-notifications-component';
import { DefaultUserMenu } from '@rio-cloud/rio-user-menu-component';
import getEnvironment from '../lib/getEnvironment';

const AppTitleComponent = injectIntl(({ intl: { formatMessage } }) => <span>{formatMessage({ id: 'PDC' })}</span>);

export const UnconnectedApplicationBody = (props) => {
    const { children, locale, activeMessages, showSessionExpired, hideSessionDialog, hasDrivers, hideDriverSidebar } =
        props;

    const showDriversSidebar = hasDrivers && !hideDriverSidebar;
    const appNavigator = <IframeResizer className="iFrameResizer" src={getServiceLocation('menu')} />;

    const notifications = <RioNotifications />;
    const userMenu = <DefaultUserMenu environment={getEnvironment()} />;

    const serviceInfoItem = <ServiceInfoComponent />;

    return (
        <IntlProvider locale={locale} messages={activeMessages} defaultLocale={DEFAULT_LOCALE}>
            <ApplicationLayout className={'ApplicationFrame'}>
                <ApplicationLayout.Header>
                    <ApplicationHeader
                        actionBarItems={[serviceInfoItem, notifications, userMenu]}
                        appNavigator={appNavigator}
                        label={<AppTitleComponent />}
                        homeRoute={<a href={getServiceLocation('home')} />}
                    />
                </ApplicationLayout.Header>
                {optional(showDriversSidebar, <AppObjectTree />)}
                <SidebarContainer display={false} />
                <ApplicationLayout.Body>
                    <NotificationsContainer />
                    <SessionExpiredDialog locale={locale} onClose={hideSessionDialog} show={showSessionExpired} />
                    <ChangelogComponent />
                    {children}
                </ApplicationLayout.Body>
            </ApplicationLayout>
        </IntlProvider>
    );
};

UnconnectedApplicationBody.propTypes = {
    locale: PropTypes.string.isRequired,
    activeMessages: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    hasDrivers: PropTypes.bool.isRequired,
    hideSessionDialog: PropTypes.func.isRequired,
    hideDriverSidebar: PropTypes.bool,
    showSessionExpired: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    hasDrivers: !!getDrivers(state).length,
});

export default connect(mapStateToProps)(UnconnectedApplicationBody);
