/* eslint-disable max-lines-per-function */
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { getSelectedInspection } from '../../selectors';

const BaseComponent = ({ pdc }) => {
    const { inspectionParts = [] } = pdc || {};

    return (
        <div className={'pdc-table'} data-testid={'inspection-results-container'}>
            {inspectionParts.map((inspection) => (
                <div
                    key={`${inspection.inspection_part_key}`}
                    className={'inspection-item display-flex justify-content-between padding-10'}
                >
                    <div className={'inspection-item-title'}>{inspection.inspection_part_value}</div>
                    {inspection.inspection_result === 'OK' ? (
                        <div className={'inspection-item-result text-color-success'}>
                            <i className={'rioglyph rioglyph-ok'} /> &nbsp;
                            <FormattedMessage id={'report.pdc.check.passed'} />
                        </div>
                    ) : (
                        <div className={'inspection-item-result text-color-danger'}>
                            <i className={'rioglyph rioglyph-remove'} /> &nbsp;
                            <FormattedMessage id={'report.pdc.check.failed'} />
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

BaseComponent.propTypes = {
    pdc: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => ({
    pdc: getSelectedInspection(state),
});

export { BaseComponent };
export default injectIntl(connect(mapStateToProps)(BaseComponent));
