import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import { FormattedMessage, injectIntl } from 'react-intl';

import optional from '../common/Optional';

const ChangelogEntryWithoutInjection = (props) => {
    const { date, messages, releaseNumber, displayReleaseNumber } = props.entry;

    // eslint-disable-next-line max-len
    const entryImageClassName =
        'margin-top-20 width-100pct border-style-solid border-width-1 border-color-lighter margin-bottom-10';

    return (
        <div className={'form-group'} data-testid={'changelog-entry'}>
            <h5>
                {displayReleaseNumber}
                <span className={'pull-right text-muted'}>{moment(date).format('LL')}</span>
            </h5>
            <ul>
                {messages.map((message, index) => (
                    <li key={`li-${releaseNumber}-${index}`} id={`li-${releaseNumber}-${index}`}>
                        <div>
                            <FormattedMessage id={message.text} />
                            {optional(message.imageUrl, <img className={entryImageClassName} src={message.imageUrl} />)}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

ChangelogEntryWithoutInjection.propTypes = {
    entry: PropTypes.shape({
        intl: PropTypes.shape({
            formatMessage: PropTypes.string.isRequired,
        }).isRequired,
        date: PropTypes.string.isRequired,
        messages: PropTypes.arrayOf(
            PropTypes.shape({
                text: PropTypes.string.isRequired,
                imageUrl: PropTypes.string,
            }),
        ).isRequired,
        displayReleaseNumber: PropTypes.string,
        releaseNumber: PropTypes.number.isRequired,
    }).isRequired,
};

export default injectIntl(ChangelogEntryWithoutInjection);
