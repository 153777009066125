import cloneDeep from 'lodash/cloneDeep';

export const appendActionsAsItems = (items = [], csvText, enabled, downloadTableAsCSV) => {
    const newItems = cloneDeep(items);
    newItems.push({
        value: csvText,
        disabled: !enabled,
        onSelect: downloadTableAsCSV,
    });

    return newItems;
};
