
      import * as Sentry from '@sentry/browser';

      Sentry.init({
        dsn: "https://4d119edee47f4a96866e016cb1ce0084@o117480.ingest.us.sentry.io/1529165",
        environment: window.location.host === 'test.rio.cloud' ? 'staging' : 'production',
        attachStacktrace: false,
        tracesSampleRate: window.location.host === 'test.rio.cloud' ? 1.0 : 0.1, // Higher sampling in staging, lower in production
      });

    