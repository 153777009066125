import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { hasInspections } from '../selectors';
import StartPanelCard from './startpage/StartPanelCard';
import StartTopPanel from './startpage/StartTopPanel';

export const renderViewOnPlayStoreButton = () => (
    <a
        href="https://play.google.com/store/apps/details?id=com.mantruckandbus.driver"
        target={'_blank'}
        className="btn btn-primary margin-right-10"
        role="button"
        rel="noreferrer"
    >
        <FormattedMessage id={'startpage.driverAppLink.buttonPlayStore'} />
    </a>
);

export const renderViewOnAppStoreButton = (locale) => {
    const language = (locale.split('-')[1] || 'gb').toLowerCase();
    const link = `https://apps.apple.com/${language}/app/man-driver/id1450811617`;

    return (
        <a href={link} target={'_blank'} className="btn btn-primary margin-right-10" role="button" rel="noreferrer">
            <FormattedMessage id={'startpage.driverAppLink.buttonAppStore'} />
        </a>
    );
};

export const renderOpenAdministrationButton = () => (
    <a href="https://users.iam.rio.cloud" className="btn btn-primary" role="button">
        <FormattedMessage id={'startpage.driverInvitationRIO.buttonOpenAdministration'} />
    </a>
);

export const StartPageComponentWithoutInjection = ({ intl: { locale }, hasPDCReports }) => (
    <div className={'StartPage'}>
        {hasPDCReports && (
            <a
                href={'https://pdc.vehicleinspection.rio.cloud/index.html#/table'}
                className="btn btn-primary btn-outline btn-link"
            >
                <span className="rioglyph rioglyph-chevron-left" aria-hidden="true" />
                <span>
                    <FormattedMessage id={'startpage.buttonBackToTableView'} />
                </span>
            </a>
        )}

        <StartTopPanel />
        <div className="row display-flex">
            <div className="col-md-6">
                <StartPanelCard
                    imageUrl={'./images/start-left.png'}
                    headline={<FormattedMessage id={'startpage.driverAppLink.title'} />}
                    text={<FormattedMessage id={'startpage.driverAppLink.text'} />}
                    buttons={[renderViewOnAppStoreButton(locale), renderViewOnPlayStoreButton()]}
                />
            </div>
            <div className="col-md-6">
                <StartPanelCard
                    imageUrl={'./images/start-right.png'}
                    headline={<FormattedMessage id={'startpage.driverInvitationRIO.title'} />}
                    text={<FormattedMessage id={'startpage.driverInvitationRIO.text'} />}
                    buttons={[renderOpenAdministrationButton()]}
                />
            </div>
        </div>
    </div>
);

StartPageComponentWithoutInjection.propTypes = {
    intl: PropTypes.shape({
        formatMessage: PropTypes.func.isRequired,
        locale: PropTypes.any,
    }).isRequired,
    hasPDCReports: PropTypes.any,
};

const mapStateToProps = (state) => ({
    hasPDCReports: hasInspections(state),
});

export default injectIntl(connect(mapStateToProps)(StartPageComponentWithoutInjection));
