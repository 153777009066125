import './less/main.less';
import root from 'window-or-global';
import qs from 'qs';
import { init } from '@sentry/browser';
import set from 'lodash/set';
import { EVENT_USER_LANGUAGE_CHANGED, EVENT_USER_PROFILE_CHANGED } from '@rio-cloud/rio-user-menu-component';

import store from './store';

import { storeDriversAction, storePdcInspections, storeVehiclesAction } from './actions/pdcActions';
import { loadObjectTreeAction } from './actions/commonActions';
import { userSessionExpired, userSessionRenewed } from './features/login/actions';

import fetchTranslation from './fetch/fetchTranslation';
import { fetchInspections } from './fetch/fetchInspections';
import fetchVehicles from './fetch/fetchVehicles';

import renderApplication from './lib/renderApplication';
import getServiceLocation from './lib/getServiceLocation';
import attachFetchErrorListener from './lib/attachFetchErrorListener';

import getEnvironment from './lib/getEnvironment';

import { mockOAuth, retrieveInitialState, setupOAuth, SIGNIN_REQUESTED } from './features/login/login';
import { accessToken } from './features/tokenHandling/accessToken';
import fetchDrivers from './fetch/fetchDrivers';
import { useNavigate } from 'react-router';

const environment = getEnvironment();

init({
    dsn: environment === 'production' ? 'https://4d119edee47f4a96866e016cb1ce0084@sentry.io/1529165' : null,
    environment,
});

let listenersAttached = false;

const attachListeners = (injectedDocument) => {
    const renewToken = () => {
        document.dispatchEvent(new window.CustomEvent(SIGNIN_REQUESTED));
    };

    injectedDocument.addEventListener(EVENT_USER_LANGUAGE_CHANGED, renewToken);
    injectedDocument.addEventListener(EVENT_USER_PROFILE_CHANGED, renewToken);
};

export const applicationLoggedIn = (
    injectedFetch = fetch,
    injectedRoot = root,
    injectedDocument = document,
    injectedStore = store,
    injectedAccessToken = accessToken,
    injectedRenderApplication = renderApplication,
) => {
    injectedRoot.window.query = qs.parse(injectedRoot.window.location.search.replace('?', ''));

    attachFetchErrorListener(injectedDocument, injectedRoot, getServiceLocation('home'));

    Promise.all([
        fetchTranslation(injectedFetch),
        fetchInspections({ injectedFetch }),
        fetchDrivers(injectedFetch),
        fetchVehicles(injectedFetch),
    ]).then(([fetchedTranslations, fetchedInspections, drivers, vehicles]) => {
        injectedStore.dispatch(storeDriversAction(drivers));
        injectedStore.dispatch(storeVehiclesAction({ vehicles }));
        injectedStore.dispatch(
            storePdcInspections({
                inspections: {
                    links: fetchedInspections.links,
                    items: fetchedInspections.items,
                    totalItemCount: fetchedInspections.totalItemCount,
                },
            }),
        );

        injectedStore.dispatch(loadObjectTreeAction());

        if (getEnvironment() !== 'production') {
            set(injectedRoot, '_testDataStore', injectedStore);
        }

        if (!listenersAttached) {
            attachListeners(injectedDocument, injectedAccessToken, injectedRoot);
            listenersAttached = true;
        }

        injectedRenderApplication('root', injectedStore, fetchedTranslations, injectedDocument);
    });
};

const oauthBehavior = (settings) => {
    const isProdEnvironment = process.env.NODE_ENV === 'production';
    const promise = isProdEnvironment ? setupOAuth(settings) : mockOAuth(settings, isProdEnvironment);

    return promise.then(() => {
        const { initialRoute } = retrieveInitialState();

        if (initialRoute) {
            const navigate = useNavigate();
            navigate(initialRoute);
        }

        return Promise.resolve();
    });
};

const getOauthConfig = (injectedStore, injectedAccessToken, injectedApplication) => ({
    onSessionError: (error) => {
        console.log('oauthConfig.onSessionError', error); // eslint-disable-line no-console
    },
    onTokenExpired: () => {
        injectedAccessToken.discardAccessToken();
        injectedStore.dispatch(userSessionExpired());
    },
    onTokenRenewed: (result) => {
        injectedAccessToken.saveAccessToken(result.accessToken);
        injectedStore.dispatch(userSessionRenewed(result));

        injectedApplication();
    },
});

const Application = (
    injectedStore = store,
    injectedAccessToken = accessToken,
    injectedOauthBehavior = oauthBehavior,
    injectedApplication = applicationLoggedIn,
) => {
    injectedOauthBehavior(getOauthConfig(injectedStore, injectedAccessToken, injectedApplication)).catch((error) => {
        console.log('auth problem?', error); // eslint-disable-line no-console
    });
};

export default Application;
