/* eslint-disable max-statements */
import compact from 'lodash/compact';
import countBy from 'lodash/countBy';
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import omit from 'lodash/omit';
import pick from 'lodash/pick';

import camelCasify from '../lib/camelCasify';

import {
    ASSET_TYPE_DRIVER,
    ASSET_TYPE_LICENCE_PLATE,
    ASSET_TYPE_TRAILER,
    ASSET_TYPE_VIN,
    ATTACHMENT_RESOURCE,
    ISSUE_RESOURCE,
    PDC_FETCH_INSPECTIONS,
    PDC_SELECTED,
    PDC_SEVERITY_HIGH,
    PDC_SEVERITY_LOW,
    PDC_SEVERITY_MEDIUM,
    PDF_DOWNLOAD_FINISHED,
    PDF_DOWNLOAD_PENDING,
    SHOW_PDC_SIDEBAR,
    STORE_DRIVERS,
    STORE_PDC_ATTACHMENT,
    STORE_PDC_INSPECTIONS,
    STORE_SELECTED_PDC,
    STORE_VEHICLES,
} from '../constants';

export const determineSeverity = (fullInspectionIssues) => {
    /* eslint-disable no-magic-numbers */
    const alertLevels = countBy(fullInspectionIssues, 'alertLevel');

    if (alertLevels.WARNING > 0) {
        return PDC_SEVERITY_HIGH;
    } else if (fullInspectionIssues.length) {
        return PDC_SEVERITY_MEDIUM;
    }

    return PDC_SEVERITY_LOW;
    /* eslint-enable no-magic-numbers */
};

export const countIssuesByLevel = (fullInspectionIssues, levels) => {
    const alertLevels = countBy(fullInspectionIssues, 'alertLevel');
    const totals = pick(alertLevels, levels);
    return (
        Object.values(totals)
            // eslint-disable-next-line no-magic-numbers
            .reduce((acc, value) => acc + value, 0)
    );
};

const buildVehicleName = (vinAsset, vehicles = []) => {
    const assetId = get(vinAsset, 'assetId');
    const vehicle = assetId && vehicles.find((v) => v.id === assetId);
    return get(vehicle, 'name');
};

const buildTrailerName = (trailerAsset) => (trailerAsset || {}).identification;

export const downloadFile = (blob) => {
    const fileName = `${new Date().toISOString()}.pdf`;
    if (window.navigator && window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blob, fileName);
    } else {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        // we need to append the element to the dom -> otherwise it will not work in firefox
        document.body.appendChild(a);

        a.click();
        a.remove();
    }
};

const initialState = { firstLoad: true };

// eslint-disable-next-line complexity, max-lines-per-function, max-statements
const PDCReducer = (state = initialState, action = {}) => {
    /* eslint-disable no-underscore-dangle */
    switch (action.type) {
        case STORE_PDC_INSPECTIONS: {
            const inspections = action.payload.inspections; // eslint-disable-line prefer-destructuring
            const links = inspections.links; // eslint-disable-line prefer-destructuring
            const inspectionsItems = map(inspections.items, camelCasify);

            return {
                ...state,
                isLoading: false,
                items: map(inspectionsItems, (inspection) => {
                    // resource already convered so skip it.
                    if (inspection.canonical) {
                        return inspection;
                    }

                    const fullInspectionIssues = compact(
                        map(inspection.issues, (inspectionIssueId) =>
                            find(inspection.referencedResources, ['resource.id', inspectionIssueId]),
                        ),
                    );

                    const driverAsset = find(inspection.assets, ['assetType', ASSET_TYPE_DRIVER]) || '';
                    const reporter =
                        driverAsset && driverAsset.assetId && find(state.drivers, ['id', driverAsset.assetId]);

                    const vinAsset = find(inspection.assets, ['identificationType', ASSET_TYPE_VIN]) || '';
                    const trailerAsset =
                        find(
                            inspection.assets,
                            (asset) =>
                                asset.identificationType === ASSET_TYPE_LICENCE_PLATE &&
                                asset.assetType === ASSET_TYPE_TRAILER,
                        ) || '';

                    const pdfLink = get(inspection.pdcReport, 'links.pdf.href');

                    const criticalIssueCount = countIssuesByLevel(fullInspectionIssues, ['WARNING']);
                    const issueCount = countIssuesByLevel(fullInspectionIssues, ['NORMAL']);

                    return {
                        canonical: true,
                        id: inspection.resource.id,
                        severity: determineSeverity(fullInspectionIssues),
                        pdfLink,
                        criticalIssueCount,
                        issueCount,
                        totalDamages: criticalIssueCount + issueCount,
                        creationTimestamp: inspection.resource.created,
                        modificationTimestamp: inspection.resource.modified,
                        reportedBy: reporter ? `${reporter.first_name} ${reporter.last_name}` : '',
                        vehicleNameInfo: buildVehicleName(vinAsset, state.vehicles),
                        vehicleAssetId: (vinAsset || {}).assetId,
                        trailerNameInfo: buildTrailerName(trailerAsset),
                        vin: vinAsset ? vinAsset.identification : '',
                    };
                }),
                links,
            };
        }
        case PDC_SELECTED: {
            const selected = state.items.find((item) => item.id === action.payload.id);
            return {
                ...state,
                // eslint-disable-next-line no-undefined
                selectedInspection: undefined,
                selected,
            };
        }
        case STORE_SELECTED_PDC: {
            const { selected } = state;

            const { _referenced_resources: referencedResources, pdc_report: pdcReport } = action.payload;

            const damages = (referencedResources || []).filter(
                (resource) => resource._resource_type === ISSUE_RESOURCE,
            );

            damages.forEach((damage) => {
                damage.attachments = (damage._referenced_resources || []).filter(
                    (resource) => resource._resource_type === ATTACHMENT_RESOURCE,
                );
            });

            let selectedInspection = {
                driver: selected.reportedBy,
                vehicle: selected.vehicleNameInfo,
                vin: selected.vin,
                assetId: selected.vehicleAssetId,
                trailer: selected.trailerNameInfo,
                damages,
                criticalDamagesCount: selected.criticalIssueCount,
                damagesCount: selected.issueCount,
                totalDamages: selected.criticalIssueCount + selected.issueCount,
            };

            if (pdcReport) {
                const {
                    location,
                    pdc_submitted_at: submittedAt,
                    inspection_parts: inspectionParts,
                    notes,
                    signature,
                    signature_content_type: signatureContentType,
                    total_distance_travelled: mileage,
                    _links: links,
                } = pdcReport;

                const {
                    pdf: { href },
                } = links;
                selectedInspection = {
                    ...selectedInspection,
                    submittedAt,
                    location,
                    mileage,
                    inspectionParts,
                    notes,
                    signature,
                    signatureContentType,
                    pdfUrl: href,
                };
            }
            return {
                ...state,
                selectedInspection,
            };
        }
        case PDF_DOWNLOAD_PENDING: {
            const { pdfUrl } = action.payload;
            const { pdcDownloads } = state;

            return {
                ...state,
                pdcDownloads: {
                    ...pdcDownloads,
                    [pdfUrl]: { pending: true },
                },
            };
        }
        case PDF_DOWNLOAD_FINISHED: {
            const { pdfUrl } = action.payload;
            const { pdcDownloads } = state;
            return {
                ...state,
                pdcDownloads: omit(pdcDownloads, pdfUrl),
            };
        }
        case STORE_DRIVERS:
            return {
                ...state,
                drivers: action.payload,
            };
        case STORE_VEHICLES:
            return {
                ...state,
                vehicles: action.payload.vehicles,
            };

        case SHOW_PDC_SIDEBAR:
            return {
                ...state,
                sidebar: {
                    ...action.payload,
                },
            };
        case STORE_PDC_ATTACHMENT:
            return {
                ...state,
                pdcAttachments: {
                    ...state.pdcAttachments,
                    [action.payload.url]: {
                        content: action.payload.content,
                        contentType: action.payload.contentType,
                    },
                },
            };
        case PDC_FETCH_INSPECTIONS:
            return {
                ...state,
                isLoading: true,
                firstLoad: false,
            };
        default:
            return state;
    }
    /* eslint-disable no-underscore-dangle */
};

export default PDCReducer;
