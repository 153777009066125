import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { Navigate } from 'react-router';

import { getUIState, getVehicleConditionFilter } from '../selectors';

import { pdcFetchInspections, removeFilter, setFilter } from '../actions/pdcActions';
import { DEFAULT_LOCALE, ROUTE_PATH_START, VEHICLE_CONDITION_FILTER } from '../constants';

import ApplicationBody from '../components/ApplicationBody';
import PDCPageComponent from '../components/PDCPageComponent';
import TextFragmentComponent from '../components/TextFragmentComponent';
import { getLocale } from '../features/login/selector';
import generateActiveMessages from '../lib/generateActiveMessages';

export const UnconnectedPDCContainer = ({
    activeMessages,
    locale,
    showSessionExpired,
    pdc,
    pdcItemsEmpty,
    onVehicleConditionFilterChange,
    filters,
    firstLoad,
    onFilterRemove,
}) => {
    if (firstLoad && pdcItemsEmpty) {
        return <Navigate to={ROUTE_PATH_START} />;
    }

    return (
        <ApplicationBody locale={locale} activeMessages={activeMessages} showSessionExpired={showSessionExpired}>
            <IntlProvider
                defaultLocale={DEFAULT_LOCALE}
                locale={locale}
                messages={activeMessages}
                textComponent={TextFragmentComponent}
            >
                <PDCPageComponent
                    pdc={pdc}
                    onVehicleConditionFilterChange={onVehicleConditionFilterChange}
                    filters={filters}
                    onFilterRemove={onFilterRemove}
                />
            </IntlProvider>
        </ApplicationBody>
    );
};

UnconnectedPDCContainer.propTypes = {
    activeMessages: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    showSessionExpired: PropTypes.bool,
    pdc: PropTypes.shape(
        PropTypes.objectOf({
            items: PropTypes.array.isRequired,
            links: PropTypes.object.isRequired,
        }),
    ).isRequired,
    pdcItemsEmpty: PropTypes.bool.isRequired,
    onVehicleConditionFilterChange: PropTypes.func.isRequired,
    filters: PropTypes.array.isRequired,
    allFilters: PropTypes.array.isRequired,
    onFilterRemove: PropTypes.func.isRequired,
    firstLoad: PropTypes.any,
};

export const mapStateToProps = (state, ownProps) => {
    const { translations } = ownProps;

    const pdc = get(state, 'pdc', {});
    const { firstLoad, items = [] } = pdc;

    const locale = getLocale(state);
    const uiState = getUIState(state) || {};

    return {
        activeMessages: generateActiveMessages(locale, translations),
        locale,
        showSessionExpired: state.login.userSessionExpired && !uiState.sessionExpiredAcknowledged,
        pdc: {
            links: pdc.links,
            items,
        },
        filters: getVehicleConditionFilter(state),
        firstLoad,
        pdcItemsEmpty: !items.length,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    onVehicleConditionFilterChange: (items) => {
        dispatch(setFilter(VEHICLE_CONDITION_FILTER, items));
        dispatch(pdcFetchInspections());
    },
    onFilterRemove: (filters) => {
        dispatch(removeFilter(filters));
        dispatch(pdcFetchInspections());
    },
});

const PDCContainer = connect(mapStateToProps, mapDispatchToProps)(UnconnectedPDCContainer);

export default PDCContainer;
