import get from 'lodash/get';

import { DEFAULT_LOCALE } from '../../constants';
import { USER_SESSION_EXPIRED, USER_SESSION_RENEWED } from './actions';

const initialState = {
    profile: null,
    accessToken: null,
    locale: DEFAULT_LOCALE,
    userSessionExpired: false,
};

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case USER_SESSION_EXPIRED:
            return {
                ...state,
                profile: null,
                accessToken: null,
                userSessionExpired: true,
            };
        case USER_SESSION_RENEWED:
            return {
                ...state,
                profile: get(action, 'payload.result.profile', null),
                accessToken: get(action, 'payload.result.accessToken', null),
                locale: get(action, 'payload.result.profile.locale', DEFAULT_LOCALE),
                userSessionExpired: false,
            };
        default:
            return state;
    }
};

export default reducer;
