import PropTypes from 'prop-types';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';

import { DEFAULT_LOCALE } from '../constants';

import ApplicationBody from '../components/ApplicationBody';
import StartPageComponent from '../components/StartPageComponent';
import { getLocale } from '../features/login/selector';
import generateActiveMessages from '../lib/generateActiveMessages';

export const UnconnectedStartContainer = ({ activeMessages, language, ...rest }) => (
    <ApplicationBody locale={language} activeMessages={activeMessages} hideDriverSidebar={true}>
        <IntlProvider defaultLocale={DEFAULT_LOCALE} locale={language} messages={activeMessages}>
            <StartPageComponent {...rest} />
        </IntlProvider>
    </ApplicationBody>
);

UnconnectedStartContainer.propTypes = {
    activeMessages: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
};

export const mapStateToProps = (state, ownProps) => {
    const { translations } = ownProps;
    const locale = getLocale(state);
    return {
        activeMessages: generateActiveMessages(locale, translations),
        language: locale,
    };
};

const StartContainer = connect(mapStateToProps)(UnconnectedStartContainer);

export default StartContainer;
