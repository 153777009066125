import get from 'lodash/get';
import set from 'lodash/set';
import toSafeInteger from 'lodash/toSafeInteger';

import { getAuthenticatedFetchRaw } from './fetch';

import moment from 'moment-timezone';

import { DRIVER_FILTER, MAX_TABLE_ENTRIES, VEHICLE_CONDITION_FILTER } from '../constants';
import getServiceLocation from '../lib/getServiceLocation';

// eslint-disable-next-line max-statements
export const getDamageFilter = (selectedFilter = []) => {
    const damageFilters = [];
    const driversFilters = [];
    const WITHOUT_DAMAGES = 'issues.0=ex=false';
    const WITH_DAMAGES = `referenced_issues=q='issue_data.alertLevel=="NORMAL"'`;
    const WITH_MAJOR_DAMAGES = `referenced_issues=q='issue_data.alertLevel=="WARNING"'`;
    const withDriver = (driverId) => `userIdCreated=="${driverId}"`;
    if (!selectedFilter.length) {
        return '';
    }

    selectedFilter.forEach((filter) => {
        if (filter.filterType === VEHICLE_CONDITION_FILTER) {
            if (filter.item === 'LOW') {
                damageFilters.push(WITHOUT_DAMAGES);
            }
            if (filter.item === 'MEDIUM') {
                damageFilters.push(WITH_DAMAGES);
            }
            if (filter.item === 'HIGH') {
                damageFilters.push(WITH_MAJOR_DAMAGES);
            }
        }
        if (filter.filterType === DRIVER_FILTER) {
            driversFilters.push(withDriver(filter.item));
        }
    });

    return [driversFilters.join(' or '), damageFilters.join(' or ')]
        .filter((item) => !!item)
        .map((filter) => `(${filter})`)
        .join(' and ');
};

export const fetchInspections = ({ injectedFetch = fetch, resourceUrl, selectedFilter }) => {
    const configuredFetch = getAuthenticatedFetchRaw(injectedFetch);
    const baseFilter = 'inspectionType=="PREDEPARTURE_CHECK"';
    const damageFilter = getDamageFilter(selectedFilter);

    const filter = encodeURIComponent(damageFilter ? `${baseFilter} and ${damageFilter}` : baseFilter);

    const path = `/inspections?resolve_refs=true&limit=${MAX_TABLE_ENTRIES}&filter=${filter}&sort=CREATED_DESCENDING`;
    const requestURL = resourceUrl || `${getServiceLocation('vehicleinspection')}${path}`;

    return configuredFetch(requestURL, set({}, 'headers.Accept', 'application/json'))
        .then((response) =>
            response.json().then((data) => ({
                links: get(data, '_links', {}),
                items: get(data, 'items', []),
                totalItemCount: toSafeInteger(response.headers.get('total_item_count')),
            })),
        )
        .catch(() => ({ items: [], links: {}, totalItemCount: 0 }));
};

export const fetchInspection = (injectedFetch = fetch, selectedId, language) => {
    const configuredFetch = getAuthenticatedFetchRaw(injectedFetch);
    const service = getServiceLocation('vehicleinspection');
    const requestUrl = `${service}/inspections/${selectedId}?resolve_refs=true&preview=true&locale=${language}`;

    return configuredFetch(requestUrl, set({}, 'headers.Accept', 'application/json')).then((response) =>
        response.json(),
    );
};

export const fetchInspectionPdf = (pdfUrl, injectedFetch = fetch) => {
    const configuredFetch = getAuthenticatedFetchRaw(injectedFetch);
    const requestOpts = {};
    set(requestOpts, 'headers.Accept', 'application/pdf');
    const timezone = encodeURIComponent(moment.tz.guess()); // eslint-disable-line
    return configuredFetch(`${pdfUrl}&timezone=${timezone}`, requestOpts)
        .then((r) => r.blob())
        .catch(console.error); // eslint-disable-line
};
