/* eslint-disable max-lines-per-function */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { getSelectedInspection } from '../../selectors';

import optional from '../common/Optional';

import BasicInfoContainer from './BasicInfoContainer';
import DamagesDetailContainer from './DamagesDetailContainer';
import DamagesHeaderContainer from './DamagesHeaderContainer';
import InspectionResultsContainer from './InspectionResultsContainer';

export const CHECK_TAB = 'CHECK';
const DETAILS_TAB = 'DETAILS';

const BaseComponent = ({ pdc = {} }) => {
    const { notes, signature, signatureContentType, totalDamages } = pdc;

    const [currentTab, setCurrentTab] = useState(CHECK_TAB);

    return (
        <div className={'container-fluid pdc-report-container'}>
            <BasicInfoContainer />
            <DamagesHeaderContainer />
            <div className={'row padding-top-10'}>
                <div className={'well padding-0'}>
                    <ul className={'nav nav-pills nav-justified'}>
                        <li className={currentTab === CHECK_TAB && 'active'}>
                            <a onClick={() => setCurrentTab(CHECK_TAB)}>
                                <FormattedMessage id={'report.pdc.check'} />
                            </a>
                        </li>
                        <li className={`${currentTab === DETAILS_TAB && 'active'} ${!totalDamages && 'disabled'}`}>
                            <a onClick={() => setCurrentTab(DETAILS_TAB)}>
                                <FormattedMessage id={'report.pdc.damagesDetail'} />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            {optional(
                currentTab === CHECK_TAB,
                <div>
                    <div className={'row padding-top-10'}>
                        <InspectionResultsContainer />
                    </div>
                    <div className={'row'}>
                        {optional(
                            notes,
                            <div className={'margin-top-10 margin-bottom-10'}>
                                <div className={'text-size-small text-color-gray'}>
                                    <FormattedMessage id={'report.pdc.additionalInformation'} />
                                </div>
                                <div>{notes}</div>
                            </div>,
                        )}

                        {optional(
                            signature,
                            <div className={'margin-top-10 margin-bottom-10'}>
                                <div className={'text-size-small text-color-gray'}>
                                    <FormattedMessage id={'report.pdc.signature'} />
                                </div>
                                <div>
                                    <img src={`data:${signatureContentType};base64, ${signature}`} />
                                </div>
                            </div>,
                        )}
                    </div>
                </div>,
            )}

            {optional(currentTab === DETAILS_TAB, <DamagesDetailContainer />)}
        </div>
    );
};

BaseComponent.propTypes = {
    pdc: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => ({
    pdc: getSelectedInspection(state),
});

export { BaseComponent };
export default injectIntl(connect(mapStateToProps)(BaseComponent));
