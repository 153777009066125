import camelCase from 'lodash/camelCase';
import each from 'lodash/each';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import map from 'lodash/map';

const camelCasify = (input) => {
    if (!isObject(input)) {
        return input;
    } else if (isArray(input)) {
        return map(input, camelCasify);
    }

    const result = {};
    each(input, (value, key) => {
        result[camelCase(key)] = camelCasify(value);
    });

    return result;
};

export default camelCasify;
