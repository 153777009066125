import get from 'lodash/get';

import { HIDE_SESSION_EXPIRED_DIALOG } from '../constants';

export const getInitialState = (windowObject = window) => ({
    sessionExpiredAcknowledged: false,
    experimentalFeatures: Boolean(get(windowObject, 'location.search').match(/experimental=true/)),
});

const UIStateReducer = (state = getInitialState(), action = {}) => {
    switch (action.type) {
        case HIDE_SESSION_EXPIRED_DIALOG:
            return {
                ...state,
                sessionExpiredAcknowledged: true,
            };
        default:
            return state;
    }
};

export default UIStateReducer;
