import ActionBarItem from '@rio-cloud/rio-uikit/lib/es/ActionBarItem';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { showChangeLog } from '../actions/changelogActions';
import { ROUTE_PATH_START } from '../constants';

import NonMatchingLink from './NonMatchingLink';

const { Icon, List, ListItem, Popover } = ActionBarItem;

const ServiceInfoComponentWithoutInjection = ({ handleChangeLogClickEvent }) => {
    const ServiceInfo = () => (
        <List>
            <ListItem icon="rioglyph-hand-right" onClick={() => Notification.info('Show Welcome Dialog')}>
                <div className="display-flex align-items-center gap-5">
                    <NonMatchingLink to={ROUTE_PATH_START}>
                        <FormattedMessage id={'landingPage.heading'} />
                    </NonMatchingLink>
                </div>
            </ListItem>
            <ListItem icon="rioglyph-exclamation-sign">
                <a onClick={handleChangeLogClickEvent}>
                    <FormattedMessage id={'changelog.whatsNew'} />
                </a>
            </ListItem>
        </List>
    );

    return (
        <ActionBarItem id={'serviceInfo'} data-testId={'service-info'}>
            <Icon>
                <span className="icon rioglyph rioglyph-info-sign" />
            </Icon>
            <Popover className="service-infopopover" title={<FormattedMessage id={'PDC'} />}>
                <ServiceInfo />
            </Popover>
        </ActionBarItem>
    );
};

ServiceInfoComponentWithoutInjection.propTypes = {
    handleChangeLogClickEvent: PropTypes.func.isRequired,
};

export const mapDispatchToProps = (dispatch) => ({
    handleChangeLogClickEvent: () => dispatch(showChangeLog()),
});

const enhancedComponent = connect(null, mapDispatchToProps)(ServiceInfoComponentWithoutInjection);

export { ServiceInfoComponentWithoutInjection };
export default injectIntl(enhancedComponent);
